import _ from "lodash";
import axios from "axios";
import store from "@/store";
import moment from "moment-timezone";
import i18n from "@/plugins/i18n";
import he from "he";

const extractValueFromProfile = (
  profile,
  dimensionGroup,
  surveyType,
  valueType,
  interactions,
  dimension,
  surveyEntityId = null,
  closed = false,
  asObject = false
) => {
  let getConstant = store.getters["Constants/getConstant"];
  if (profile && profile[getConstant(dimensionGroup)]) {
    let data = profile[getConstant(dimensionGroup)]
      ? profile[getConstant(dimensionGroup)]
      : false;

    let interactionBySurveyType = {};
    if (surveyEntityId === null) {
      interactionBySurveyType = _.findLast(interactions, interaction => {
        return isNaN(surveyType)
          ? parseInt(interaction.surveyTypeId) ===
              parseInt(getConstant(surveyType)) &&
              (interaction.userEndDate == getConstant("NULL_DATE") || closed)
          : parseInt(interaction.surveyTypeId) === parseInt(surveyType) &&
              interaction.userEndDate == getConstant("NULL_DATE");
      });
    } else {
      interactionBySurveyType = { surveyEntityId: parseInt(surveyEntityId) };
    }

    if (dimension && data && interactionBySurveyType) {
      if (
        data[getConstant(dimension)] &&
        data[getConstant(dimension)][interactionBySurveyType.surveyEntityId] &&
        !_.isEmpty(
          data[getConstant(dimension)][interactionBySurveyType.surveyEntityId]
        )
      ) {
        let lastValue = _.find(
          data[getConstant(dimension)][interactionBySurveyType.surveyEntityId],
          el => el.vigence == 1
        );
        return lastValue !== null && lastValue !== undefined
          ? asObject
            ? lastValue
            : lastValue[valueType]
          : false;
      } else {
        return false;
      }
    } else {
      return false; // default value
    }
  } else {
    return false;
  }
};
const getClassByValueAndBase = (value, base, color, backgroundColor) => {
  var calcValue = value;
  var calcBase = base;
  if (base === 100) {
    calcValue = value / 10;
    calcBase = 10;
  }
  if (backgroundColor && color) {
    return (
      "base" +
      calcBase +
      "-full" +
      (value !== false && value !== null && !isNaN(calcValue)
        ? "-" + parseInt(Math.round(calcValue))
        : "")
    );
  } else if (backgroundColor) {
    return (
      "base" +
      calcBase +
      "-bg" +
      (value !== false && value !== null && !isNaN(calcValue)
        ? "-" + parseInt(Math.round(calcValue))
        : "")
    );
  } else if (color) {
    return (
      "base" +
      calcBase +
      "-color" +
      (value !== false && value !== null && !isNaN(calcValue)
        ? "-" + parseInt(Math.round(calcValue))
        : "")
    );
  }
};

const getColorByValueAndBase = (value, base, hex = true) => {
  let calcBase = false;
  let calcValue = false;
  if (base === 100) {
    calcBase = 10;
    calcValue = Math.round(parseInt(value) / 10);
  } else if (base === 3) {
    calcBase = 6;
    calcValue = Math.round(parseInt(value)) * 2;
  } else {
    calcBase = parseInt(base);
    calcValue = Math.round(value);
  }

  if (calcBase === 10) {
    if (calcValue === 0) {
      return hex ? "#ea3323" : hexToRgb("#ea3323");
    } else if (calcValue === 1) {
      return hex ? "#ea4025" : hexToRgb("#ea4025");
    } else if (calcValue === 2) {
      return hex ? "#ec5a29" : hexToRgb("#ec5a29");
    } else if (calcValue === 3) {
      return hex ? "#ee7c30" : hexToRgb("#ee7c30");
    } else if (calcValue === 4) {
      return hex ? "#f19d36" : hexToRgb("#f19d36");
    } else if (calcValue === 5) {
      return hex ? "#f5c243" : hexToRgb("#f5c243");
    } else if (calcValue === 6) {
      return hex ? "#cabd40" : hexToRgb("#cabd40");
    } else if (calcValue === 7) {
      return hex ? "#a0b841" : hexToRgb("#a0b841");
    } else if (calcValue === 8) {
      return hex ? "#7ab447" : hexToRgb("#7ab447");
    } else if (calcValue === 9) {
      return hex ? "#5db150" : hexToRgb("#5db150");
    } else if (calcValue === 10) {
      return hex ? "#4ead5b" : hexToRgb("#4ead5b");
    } else if (calcValue >= 11) {
      return hex ? "#3acc4e" : hexToRgb("#3acc4e");
    } else {
      return hex ? "#ababab" : hexToRgb("#ababab");
    }
  } else {
    if (calcValue == 0) {
      return hex ? "#66db50" : hexToRgb("#66db50");
    } else if (calcValue == 1) {
      return hex ? "#66db50" : hexToRgb("#66db50");
    } else if (calcValue == 2) {
      return hex ? "#a3be58" : hexToRgb("#a3be58");
    } else if (calcValue == 3) {
      return hex ? "#bfdb51" : hexToRgb("#bfdb51");
    } else if (calcValue == 4) {
      return hex ? "#e0e331" : hexToRgb("#e0e331");
    } else if (calcValue == 5) {
      return hex ? "#efb949" : hexToRgb("#efb949");
    } else if (calcValue >= 6) {
      return hex ? "#f3906e" : hexToRgb("#f3906e");
    } else {
      return hex ? "#ababab" : hexToRgb("#ababab");
    }
  }
};

const formatNumber = (number, precission) => {
  let multiplier = Math.pow(10, precission);
  return Math.round(number * multiplier) / multiplier;
};

const hexToRgb = hex =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16));

const calcEvolution = (
  dimensionGroup,
  dimension,
  surveyType,
  interactions,
  data,
  divider = 1
) => {
  let getConstant = store.getters["Constants/getConstant"];
  let result = {
    series: [],
    discrete: []
  };
  let filteredInteractions = _.filter(
    interactions,
    int => parseInt(int.surveyTypeId) === parseInt(getConstant(surveyType))
  );

  let mergedDimensions = [];
  _.each(filteredInteractions, interaction => {
    if (
      data[getConstant(dimensionGroup)] &&
      data[getConstant(dimensionGroup)] &&
      data[getConstant(dimensionGroup)][getConstant(dimension)] &&
      data[getConstant(dimensionGroup)][getConstant(dimension)][
        interaction.surveyEntityId
      ]
    )
      mergedDimensions = _.concat(
        mergedDimensions,
        data[getConstant(dimensionGroup)][getConstant(dimension)][
          interaction.surveyEntityId
        ]
      );
  });

  if (!_.isEmpty(mergedDimensions)) {
    _.each(mergedDimensions, dimen => {
      let endDate = dimen.startDate;
      result.series.push({
        value: dimen.dimensionValue / divider,
        date: moment(endDate).format("YYYY-MM-DD hh:mm:ss")
      });
    });
    result.series = _.orderBy(result.series, "date");
  }

  if (result.series.length === 1) {
    result.series.push({
      date:
        moment(_.last(result.series).date).format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD")
          ? moment(_.last(result.series).date)
              .subtract(1, "days")
              .format("YYYY-MM-DD hh:mm:ss")
          : moment().format("YYYY-MM-DD hh:mm:ss"),
      value: _.last(result.series).value
    });

    result.series = _.orderBy(result.series, "date", "ASC");
  } else if (
    result.series.length > 0 &&
    moment(_.last(result.series).date).format("YYYY-MM-DD") !==
      moment().format("YYYY-MM-DD")
  ) {
    result.series.push({
      date: moment().format("YYYY-MM-DD hh:mm:ss"),
      value: _.last(result.series).value
    });
  }
  return result;
};

const extractRootDomain = function(url) {
  return url.replace(/.+\/\/|www.|\..+/g, "");
};

const calculatePercent = (solved, total) => {
  return Math.round((solved * 100) / total);
};

const progressBarColor = (solved, total) => {
  const progress = (solved * 100) / total;
  if (progress <= 33) {
    return "danger";
  } else if (progress > 33 && progress <= 66) {
    return "warning";
  } else if (progress > 66) {
    return "success";
  }
};

const getAssetIcon = type => {
  let result = {};
  switch (type) {
    case "youtube_iframe":
      result.class = "socicon-youtube text-danger";
      break;
    case "ted_iframe":
      result.file = "files/ted.svg";
      break;
    case "vimeo_iframe":
      result.class = "socicon-vimeo text-danger";
      break;
    case "iframe":
      result.image = "video-camera.svg";
      break;
    case "audea_link":
      result.image = "warning-circle.svg";
      break;
    case "external_link":
      result.class = "fas fa-link";
      break;
    default:
      result.class = `fas fa-file-${type}`;
      break;
  }
  return result;
};

const getUsersBreaches = (data, stakeholders) => {
  let breaches = [];
  let usersBreaches = [];

  _.each(data, rawBreach => {
    let breach = _.clone(rawBreach);
    breach.stakeholders = _.map(breach.stakeholders, stakeholder => {
      return { ...stakeholder, date: breach.breach.breachDate };
    });
    breaches = [...breaches, breach.stakeholders];
  });
  breaches = breaches.flat(1);

  _.each(breaches, breach => {
    if (
      !_.some(usersBreaches, {
        stakeholderId: breach.stakeholderId
      })
    ) {
      let lastBreachDate = _.filter(breaches, {
        stakeholderId: breach.stakeholderId
      });
      lastBreachDate = _.sortBy(lastBreachDate, ["date"])[
        lastBreachDate.length - 1
      ].date;
      usersBreaches.push({
        stakeholderId: breach.stakeholderId,
        user: _.find(stakeholders, {
          stakeholderId: breach.stakeholderId
        }),
        pwd: breach.status.password ? 1 : 0,
        pwdSolved: breach.status.password && breach.status.solved ? 1 : 0,
        noPwd: !breach.status.password ? 1 : 0,
        noPwdSolved: !breach.status.password && breach.status.solved ? 1 : 0,
        lastBreachDate: lastBreachDate
      });
    } else {
      let updatedUserBreach = _.find(usersBreaches, {
        stakeholderId: breach.stakeholderId
      });
      const index = _.findIndex(usersBreaches, updatedUserBreach);
      updatedUserBreach = {
        ...updatedUserBreach,
        pwd: updatedUserBreach.pwd + (breach.status.password ? 1 : 0),
        pwdSolved:
          updatedUserBreach.pwdSolved +
          (breach.status.password && breach.status.solved ? 1 : 0),
        noPwd: updatedUserBreach.noPwd + (!breach.status.password ? 1 : 0),
        noPwdSolved:
          updatedUserBreach.noPwdSolved +
          (!breach.status.password && breach.status.solved ? 1 : 0)
      };

      usersBreaches[index] = updatedUserBreach;
    }
  });

  return usersBreaches;
};

const removeProfileData = () => {
  store.dispatch("Admin/resetUsers", {}, { root: true });
  store.dispatch("Admin/resetDepartments", {}, { root: true });
  store.dispatch("Controller/resetUsers", {}, { root: true });
  store.dispatch("Controller/resetDepartments", {}, { root: true });
  store.dispatch("Users/reset", {}, { root: true });
  store.dispatch("Departments/reset", {}, { root: true });
  store.dispatch("Company/reset", {}, { root: true });
  store.dispatch("ActionPlan/reset", {}, { root: true });
  store.dispatch("Organization/reset", {}, { root: true });
  store.dispatch("Phishing/reset", {}, { root: true });
  store.dispatch("Constants/reset", {}, { root: true });
};

const getEditedProfiles = (profiles, companyId) => {
  let allProfiles = ["USER", "ADMIN", "CONTROLLER"];
  let result = {};

  profiles.forEach(profile => {
    result[reverseProfile(profile)] = [companyId];
  });

  _.each(allProfiles, profile => {
    if (result[profile] == undefined) {
      result[profile] = [];
    }
  });

  return result;
};

const parseProfile = profile => {
  switch (profile.toUpperCase()) {
    case "USER":
      return i18n.t("menu.toggle.user");
    case "CONTROLLER":
      return i18n.t("menu.toggle.controller");
    case "ADMIN":
      return i18n.t("menu.toggle.admin");
  }
};

const reverseProfile = profile => {
  switch (profile.toUpperCase()) {
    case i18n.t("menu.toggle.user").toUpperCase():
      return "USER";
    case i18n.t("menu.toggle.controller").toUpperCase():
      return "CONTROLLER";
    case i18n.t("menu.toggle.admin").toUpperCase():
      return "ADMIN";
  }
};

const getMimetype = base64 => {
  switch (base64.slice(0, 0).toString()) {
    case "/":
      return "jpg";
    case "i":
      return "png";
    case "Q":
      return "bmp";
    case "S":
      return "tiff";
    case "R":
      return "gif";
    default:
      return "png";
  }
};

const checkCompanies = companies => {
  localStorage.removeItem("managedCompanyId");
  localStorage.removeItem("companySelected");
  if (companies.length <= 1) {
    localStorage.setItem("managedCompanyId", companies[0]);
    localStorage.setItem("companySelected", companies[0]);
  }
};

const getError = (code, params = {}) => {
  const result =
    (code !== "" &&
      i18n.t(`errors.${code}`, Object.keys(params).length > 0 ? params : {})) ||
    i18n.t("errors.default");
  return result;
};

const checkSVG = async surveyTypeId => {
  let path =
    "https://dkdzifbe1uos6.cloudfront.net/gaming_svg/" + surveyTypeId + ".svg";

  await axios
    .get(path)
    .then(() => true)
    .catch(() => true);
};

const unflatten = data => {
  if (Object(data) !== data || Array.isArray(data)) return data;
  var regex = /\.?([^.[\]]+)|\[(\d+)\]/g,
    resultholder = {};
  for (var p in data) {
    var cur = resultholder,
      prop = "",
      m;
    while ((m = regex.exec(p))) {
      cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
      prop = m[2] || m[1];
    }
    cur[prop] = he.decode(data[p]);
  }
  return resultholder[""] || resultholder;
};

export {
  checkSVG,
  getError,
  checkCompanies,
  reverseProfile,
  parseProfile,
  removeProfileData,
  extractValueFromProfile,
  getClassByValueAndBase,
  formatNumber,
  getEditedProfiles,
  getColorByValueAndBase,
  calcEvolution,
  extractRootDomain,
  getAssetIcon,
  calculatePercent,
  progressBarColor,
  getUsersBreaches,
  getMimetype,
  unflatten
};
